import omit from 'lodash/omit';
import { IFeatureTile, IFeatureTiles } from './FeatureTiles.types';
import * as Tiles from './FeatureTiles.styles';
import FeatureTile from './FeatureTile';

/**
 * Number of Feature Tiles is <strong>3 to 5</strong>
 *
 * When we have <strong>3 Feature Tiles</strong> then the column structure for each Feature Tile is
 * <strong>3 for Large screen and Medium Screen devices.</strong>
 *
 * When we have <strong>4 Feature Tiles</strong> then the column structure for each Feature Tile is
 * <strong>3 for Large screen devices and 6 for Medium screen devices</strong>
 *
 * When we have <strong>5 Feature Tiles</strong> then the column structure for each Feature Tile is
 * <strong>2 for Large screen devices and 4 for Medium screen devices</strong>
 */
const FeatureTiles: React.FC<IFeatureTiles> = ({ featureTiles, orderedList }: IFeatureTiles) => {
  const LARGE_SIZE = featureTiles.length > 4 ? 2 : 3;
  const MEDIUM_SIZE = featureTiles.length === 4 ? 5 : 4;

  const size = {
    xs: 12,
    md: MEDIUM_SIZE,
    xl: LARGE_SIZE
  };

  return (
    <Tiles.Wrapper role="list" as={`${orderedList ? 'ol' : 'ul'}`}>
      {featureTiles.map((featureTile: IFeatureTile) => {
        return (
          <Tiles.Item itemSize={size} key={featureTile.id}>
            <Tiles.ItemWrapper>
              <FeatureTile {...omit(featureTile, 'key')} />
            </Tiles.ItemWrapper>
          </Tiles.Item>
        );
      })}
    </Tiles.Wrapper>
  );
};

FeatureTiles.displayName = 'FeatureTiles';

export default FeatureTiles;
