import { FONT_WEIGHT, media, mediaMap } from '@belong/themes';
import styled, { css } from 'styled-components';
import { PictureContainer } from '../../../media/PictureContainer';

const SVG_SIZE = {
  xs: '4.8rem',
  md: '9.6rem'
};

const IMG_SIZE = {
  xs: '9.6rem',
  md: '11.2rem'
};

export const Wrapper = styled.div<{ isLargeTextTile?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: ${({ isLargeTextTile }) => (isLargeTextTile ? 'center' : 'flex-start')};
  ${media('md')`
    flex-direction: column;
    align-items: center;
  `};
`;

export const Container = styled.div`
  align-items: flex-start;
  padding-bottom: 1.6rem;
  display: flex;
  flex-direction: row;

  ${media('md')`
      margin-bottom: 4px;
      flex-direction: column;
      align-items: center;
  `};
`;

export const Content = styled.div<{ isTopAligned: boolean; isLargeTextTile?: boolean }>`
  display: flex;
  align-items: ${({ isTopAligned }) => (isTopAligned ? 'flex-start' : 'center')};
  margin-left: 1.6rem;

  ${media('md')`
    margin-top: ${({ isLargeTextTile }) => (isLargeTextTile ? 0 : '2.4rem')};
    margin-left: 0;
    align-items: flex-start;
  `}

  a, a > span {
    display: inline;
    overflow-wrap: anywhere;
  }
`;

export const Image = styled(PictureContainer)`
  ${({ contentType }) => {
    const OPTIONS = contentType === 'svg' ? SVG_SIZE : IMG_SIZE;

    return mediaMap(
      OPTIONS,
      (val: string) => css`
        width: ${val};
        height: ${val};
      `
    );
  }}
  display: block;
  object-fit: ${({ contentType }) => (contentType === 'svg' ? 'contain' : 'cover')};
`;

export const DisplayText = styled.span`
  color: ${({ theme }) => theme?.foregroundColorDark};
  font-size: 6.4rem;
  font-height: 6.4rem;
  font-weight: ${FONT_WEIGHT.BOLD};
  text-align: center;

  ${media('md')`
    font-size: 9.5rem;
    font-height: 9.5rem;
  `}
`;
