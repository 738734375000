import { media, mediaMap } from '@belong/themes';
import { ResponsiveProp } from '@belong/types';
import styled, { css } from 'styled-components';

export const Item = styled.li<{ itemSize: ResponsiveProp<number | 'auto'> }>`
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;

  ${({ itemSize }) =>
    mediaMap(itemSize, (size: number | 'auto') =>
      size === 'auto'
        ? css`
            flex-grow: initial;
            flex-basis: initial;
          `
        : css`
            flex-basis: ${(size / 12) * 100}%;
            max-width: ${(size / 12) * 100}%;
          `
    )}

  border-bottom: 0.2rem solid ${({ theme }) => theme.borderColor};
  list-style-type: none;

  ${media('md')`
    border-bottom: none;
  `};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ItemWrapper = styled.div`
  padding: 1.6rem;
  height: 100%;
  display: flex;

  ${media('md')`
    padding: 3.2rem 1.6rem;
  `};
`;

export const Wrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
