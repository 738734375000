import React from 'react';

import type { ColumnWidth, ITestable } from '@belong/types';
import { ACCENTSTRIP_VARIANTS } from '@belong/ui-core';
import useAnalyticsEvents from '@belong/providers/analyticsEvents';
import { buttonClick } from '@belong/analytics';

import { PictureContainer } from '../../media/PictureContainer';
import { Button } from '../../actions';
import { Column, Columns, Stack } from '../../ui';
import { IBoardingPassProps, IBoardingPassTileProps } from './BoardingPass.types';
import { BoardingPassTile } from './BoardingPassTile';
import { AccentStrip, ClippingContainer, DetailsContainer, ItemWrapper } from './BoardingPass.styles';

const BoardingPassItem: React.FC<
  React.PropsWithChildren<
    { tile: IBoardingPassTileProps; isFirst?: boolean } & ITestable &
      Pick<IBoardingPassTileProps, 'isClippingTile' | 'isLast'>
  >
> = ({ children, isClippingTile, isLast, tile, ...others }) => (
  <ItemWrapper isClippingTile={isClippingTile} {...others}>
    <Stack space="large">
      <BoardingPassTile isClippingTile={isClippingTile} isLast={isLast} {...tile} />
      {children}
    </Stack>
  </ItemWrapper>
);

BoardingPassItem.displayName = 'BoardingPassItem';

const ClippingItem = ({ clippingTile }: Pick<IBoardingPassProps, 'clippingTile'>) => {
  const analyticsEvents = useAnalyticsEvents();

  return (
    <BoardingPassItem tile={clippingTile} data-testid="clipping-item" isClippingTile>
      {clippingTile.ctaLink && (
        <Button
          data-testid="cta-button"
          width="full"
          {...clippingTile.ctaLink}
          onClick={() => {
            analyticsEvents.send({
              ...buttonClick({
                label: 'set-up-roaming',
                text: clippingTile.ctaLink?.label
              }),
              component: 'boarding-pass'
            });
          }}
        />
      )}
    </BoardingPassItem>
  );
};

ClippingItem.displayName = 'ClippingItem';

export const BoardingPass: React.FC<IBoardingPassProps> = ({
  clippingPosition = 'start',
  clippingSize = 'medium',
  clippingTile,
  tiles,
  detailesBannerImage
}: IBoardingPassProps) => (
  <Columns collapseBelow="md" alignY="stretch" reverse={clippingPosition === 'end' ? 'whenNotCollapsed' : 'never'}>
    <Column width={clippingSize === 'medium' ? '1/4' : '1/3'}>
      <ClippingContainer
        data-testid="lead-clipping-container"
        clippingPosition={clippingPosition}
        clippingSize={clippingSize}
      >
        <AccentStrip data-testid="foil-slick-horiz" variant={ACCENTSTRIP_VARIANTS.FOIL_SLICK} />
        <AccentStrip
          data-testid="foil-slick-vert"
          clippingPosition={clippingPosition}
          isFlexEnd={clippingPosition === 'end'}
          isVertical
          variant={ACCENTSTRIP_VARIANTS.FOIL_SLICK}
        />
        <ClippingItem clippingTile={clippingTile} />
      </ClippingContainer>
    </Column>
    <Column width={clippingSize === 'medium' ? '3/4' : '2/3'}>
      <DetailsContainer id="details-container" bannerContainer={!!detailesBannerImage}>
        {detailesBannerImage ? (
          <PictureContainer
            contentType={detailesBannerImage.contentType}
            src={{
              xs: [detailesBannerImage.src, { w: 360, h: 260, fit: 'fill', f: 'face' }],
              md: [detailesBannerImage.src, { w: 520, h: 390, fit: 'fill', f: 'face' }],
              lg: [detailesBannerImage.src, { w: 720, h: 510, fit: 'fill', f: 'face' }],
              sm: [detailesBannerImage.src, { w: 800, h: 600, fit: 'fill', f: 'face' }],
              xl: [detailesBannerImage.src, { w: 1100, h: 510, fit: 'fill', f: 'face' }]
            }}
          />
        ) : (
          <Columns alignX="center" collapseBelow="md">
            {tiles.map((tile: IBoardingPassTileProps, key) => (
              <Column key={tile.id} width={`1/${tiles.length}` as ColumnWidth}>
                <BoardingPassItem tile={tile} isFirst={!key} isLast={key === tiles.length - 1} />
              </Column>
            ))}
          </Columns>
        )}
      </DetailsContainer>
    </Column>
  </Columns>
);

BoardingPass.displayName = 'BoardingPass';
