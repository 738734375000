import { useEffect, useState } from 'react';
import { ALIGNMENT } from '@belong/themes';
import { IFeatureTile } from '../FeatureTiles.types';
import ContentFormatter from '../../../styles/ContentFormatter';
import { RichText, TEXT_NODES } from '../../../styles/Typography/RichText';
import * as Tile from './FeatureTile.styles';

const FeatureTile: React.FC<IFeatureTile> = ({ copy, image, displayText, displayTextA11yLabel }: IFeatureTile) => {
  const [isLargeTextTile, setIsLargeTextTile] = useState(false);

  useEffect(() => {
    setIsLargeTextTile(displayText !== undefined && displayText !== '');
  }, [displayText]);

  return (
    <Tile.Wrapper isLargeTextTile={isLargeTextTile}>
      <Tile.Container>
        {image && (
          <Tile.Image
            src={{
              xs: [image.src, { w: 96, h: 96, fit: 'fill', f: 'faces' }],
              md: [image.src, { w: 112, h: 112, fit: 'fill', f: 'faces' }]
            }}
            alt={image.alt}
            contentType={image.contentType}
          />
        )}
        <Tile.Content isLargeTextTile={isLargeTextTile} isTopAligned={(image && image.contentType === 'svg') ?? false}>
          <ContentFormatter
            spacing="small"
            alignment={{
              xs: isLargeTextTile ? ALIGNMENT.center : ALIGNMENT.left,
              md: ALIGNMENT.center
            }}
          >
            {displayText && <Tile.DisplayText aria-label={displayTextA11yLabel}>{displayText}</Tile.DisplayText>}
            {copy && (
              <RichText
                h1={TEXT_NODES.headingXSmall}
                h2={TEXT_NODES.headingXSmall}
                h3={TEXT_NODES.headingXSmall}
                h4={TEXT_NODES.headingXSmall}
                h5={TEXT_NODES.headingXSmall}
                h6={TEXT_NODES.headingXSmall}
                html={copy}
              />
            )}
          </ContentFormatter>
        </Tile.Content>
      </Tile.Container>
    </Tile.Wrapper>
  );
};

FeatureTile.displayName = 'FeatureTile';

export default FeatureTile;
