import styled from 'styled-components';

import { COLOURS, media } from '@belong/themes';
import { AccentStrip as BaseAccentStrip } from '@belong/ui-core';

import { IBoardingPassProps, IBoardingPassTileProps } from './BoardingPass.types';

type ClippingProps = Pick<IBoardingPassProps, 'clippingPosition' | 'clippingSize'>;

export const ItemWrapper = styled.div<
  ClippingProps & Pick<IBoardingPassTileProps, 'isClippingTile'> & { isFirst?: boolean }
>`
  display: flex;
  height: 100%;
  flex-direction: column;
  ${({ isClippingTile }) =>
    isClippingTile
      ? `
          padding: 2.4rem 1.6rem;
          justify-content: center;
        `
      : `
          padding-top: 1.6rem;
        `}

  ${media('md')`
    padding: 3.2rem ${({ isClippingTile }) => (isClippingTile ? '2.4rem' : '0')};
  `};
`;

export const AccentStrip = styled(BaseAccentStrip)<Partial<ClippingProps>>`
  position: absolute;
  top: 0;
  ${({ clippingPosition }) => (clippingPosition === 'end' ? 'right: 0' : 'left: 0')};
  display: ${({ isVertical }) => (isVertical ? 'none' : 'block')};

  ${media('md')`
    display: ${({ isVertical }) => (isVertical ? 'block' : 'none')};
  `}
`;

const border = `2px dashed ${COLOURS.GREY_300}`;
export const ClippingContainer = styled.div<ClippingProps>`
  position: relative;
  height: 100%;
  border-radius: 8px;
  background-color: ${p => p.theme.backgroundColor};
  border-bottom: ${border};
  box-shadow: ${p => p.theme.shadows.KEY_LIGHT};
  padding-top: 0.8rem;

  ${media('md')`
    border-bottom: none;
    padding: 0;

    ${({ clippingPosition }) => {
      if (clippingPosition === 'start') {
        return `
          border-right: ${border};
          padding-left: 0.8rem;
        `;
      }
      return `
        border-left: ${border};
        padding-right: 0.8rem;
      `;
    }}
  `}
`;

export const DetailsContainer = styled.div<{ bannerContainer?: boolean }>`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  ${({ bannerContainer }) => !bannerContainer && 'padding: 1.6rem;'}
  border-radius: 8px;
  border-top: unset;
  background-color: ${p => p.theme.backgroundColorLight};
  z-index: 1;
  box-shadow: ${p => p.theme.shadows.KEY_LIGHT};
  overflow: hidden;

  ${media('md')`
      padding: 0 2.4rem;
  `}

  > picture {
    position: unset;
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      ${media('md')`
        position: absolute;
        top: 0;
        left: 0;
        object-position: initial;
    `}
    }
  }

  > div {
    align-self: center;
    flex-grow: 1;
  }
`;
