import React from 'react';
import { ALIGNMENT } from '@belong/themes';
import { IBoardingPassTileProps } from '../BoardingPass.types';
import ContentFormatter from '../../../styles/ContentFormatter';
import { RichText, TEXT_NODES } from '../../../styles/Typography/RichText';
import * as Tile from './BoardingPassTile.styles';

export const BoardingPassTile: React.FC<IBoardingPassTileProps> = ({
  copy,
  isClippingTile,
  image,
  isLast
}: IBoardingPassTileProps) => {
  return (
    <Tile.Wrapper data-testid="boarding-pass-tile" isClippingTile={isClippingTile} isLast={isLast}>
      <Tile.Container data-testid="bpt-container" isClippingTile={isClippingTile} isSvg={image.contentType === 'svg'}>
        <Tile.Image
          src={{
            xs: [image.src, { w: 96, h: 96, fit: 'fill', f: 'faces' }],
            md: [image.src, { w: 112, h: 112, fit: 'fill', f: 'faces' }]
          }}
          alt={image.alt}
          contentType={image.contentType}
        />
        <Tile.Content data-testid="bpt-content" isTopAligned={image.contentType === 'svg'}>
          <ContentFormatter
            spacing="small"
            alignment={{
              xs: isClippingTile ? ALIGNMENT.center : ALIGNMENT.left,
              md: ALIGNMENT.center
            }}
          >
            {copy && (
              <RichText
                h1={TEXT_NODES.headingMedium}
                h2={TEXT_NODES.headingMedium}
                h3={TEXT_NODES.headingMedium}
                h4={TEXT_NODES.headingMedium}
                h5={TEXT_NODES.headingMedium}
                h6={TEXT_NODES.headingMedium}
                html={copy}
              />
            )}
          </ContentFormatter>
        </Tile.Content>
      </Tile.Container>
    </Tile.Wrapper>
  );
};

BoardingPassTile.displayName = 'BoardingPassTile';
