import styled from 'styled-components';
import { media } from '@belong/themes';
import { IBoardingPassTileProps } from '../BoardingPass.types';

export { Content, Image } from '../../FeatureTiles/FeatureTile/FeatureTile.styles';

export const Container = styled.div<Pick<IBoardingPassTileProps, 'isClippingTile'> & { isSvg?: boolean }>`
  ${({ isClippingTile }) => !isClippingTile && 'padding-bottom: 1.6rem;'};
  display: flex;
  align-items: flex-start;
  align-items: center;
  flex-direction: ${({ isClippingTile }) => (isClippingTile ? 'column' : 'row')};

  ${({ isClippingTile, isSvg }) =>
    isClippingTile &&
    isSvg &&
    `
    picture img {
      height: 9.6rem;
      width: 9.6rem;
    }
  `}

  ${media('md')`
    flex-direction: column;
  `};
`;

export const Wrapper = styled.div<Pick<IBoardingPassTileProps, 'isClippingTile' | 'isLast'>>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;

  ${Container} {
    ${({ isClippingTile, isLast, theme }) =>
      !isClippingTile && !isLast && `border-bottom: 1px solid ${theme.borderColor};`}
  }

  ${media('md')`
    border-bottom: unset;
    align-items: center;
    flex-grow: 0;

    ${Container} {
      border: 0;
      padding-bottom: 0;
    }
  `};
`;
